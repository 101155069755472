import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import $ from 'jquery'

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const bannerSwiper = new Swiper(".banner-swiper", {
	autoplay: {
		delay: 5000
	},
	observer: true,
	observeParents: true,
	observeSlideChildren: true,
	pagination: {
		el: ".swiper-pagination.banner-pagination",
		clickable: true
	},
	// Navigation arrows
	navigation: {
		nextEl: ".banner-button-next",
		prevEl: ".banner-button-prev",
	},
});


$(".banner-swiper").each(function () {
    let videoList = $(this).find("video")
    if (videoList.length) { 
        bannerSwiper.autoplay.stop() //如果有视频，禁止循环播放
        bannerSwiper.onSlideChangeEnd = function (swiper) { //滚动停止后视频停止播放
            for (var i = 0; i < videoList.length; i++) {
                videoList[i].pause();
            }
        }
    } else {
        bannerSwiper.autoplay.start(); //没视频时，每隔3秒播放
    }
})
